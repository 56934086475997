.wrapper {
  max-width: 100%;
  overflow: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
  padding: 13px 15px 45px;
  min-height: 100vh;
}

.content {
  flex: 1 0 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  z-index: 1;
  padding: 20px 0;
}

.logo {
  display: flex;
  align-items: center;
}

.logo svg {
  width: 165px;
  height: 30px;
}

.heading {
  margin-bottom: 8px;
  max-width: 395px;
}

.description {
  max-width: 375px;
  margin-bottom: 32px;
}

.image404 {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  left: 420px;
  z-index: -1;
}

@media screen and (--maxWidth900) {
  .image404 {
    position: static;
  }
}

.image404 svg {
  max-width: calc(100vw - 335px);
  height: auto;
}

@media screen and (--maxWidth900) {
  .image404 svg {
    max-width: 100%;
  }
}
